import React, {useMemo, useState, useCallback, useEffect} from 'react';
import './App.css';

import { locations } from './locations';
import Location from './location';

const defaultSpeed = 200;

function App() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [speed, setSpeed] = useState(defaultSpeed);
  const [spin, setSpin] = useState(false);
  const [showText, setShowText] = useState(false);
  const [hasSpun, setHasSpun] = useState(false);

  const offset = useMemo(() => {
    return currentSlide * -100;
  }, [currentSlide]);

  const speedDifferential = useMemo(()=>{
    return Math.random() * (15 - 7) + 7;
  }, []);

  const useTranstion = useMemo(() => {
    return currentSlide === 0 ? false : true;
  }, [currentSlide]);

  const currentSet = useMemo(() => {
    const currentSetArr = [];
    currentSetArr.push(locations[currentIndex]);

    if(currentIndex === locations.length - 1){
      currentSetArr.push(locations[0]);
    } else {
      currentSetArr.push(locations[currentIndex + 1]);
    }
    
    return currentSetArr;
  }, [currentIndex])

  const next = useCallback(() => {
    setCurrentSlide(1);
    setTimeout(()=>{
      if(currentIndex === locations.length - 1){
        setCurrentIndex(0);
      } else {
        setCurrentIndex(prevCurrentIndex => prevCurrentIndex + 1);
      }
      setCurrentSlide(0);
      setSpeed(speed + (speedDifferential * (speed / 100)));
    }, speed)
  }, [currentIndex, speed, speedDifferential])

  useEffect(() => {
    if(!spin) return;
    setHasSpun(true);
    setShowText(false);
    if(speed < 1000){
      setTimeout(()=>{
        next()
      }, 40)
    } else {
      setSpeed(defaultSpeed);
      setSpin(false);
      setShowText(true);
    }
  }, [speed, spin, next]);

  

  return (
    <div className="App">
      <div className="locations-wrapper">

        <div className="button-holder" style={{height: hasSpun ? '90vh' : '500px'}}>
          <img className="logo" src="/wz2.png" alt="wz-logo"/>
          <div className={"description " + (hasSpun ? "hide" : '')}>
            <p>Spin the wheel to find a landing spot for your team!</p>
          </div>
          <div>
            <button className="button" onClick={()=> setSpin(true)}>Spin {hasSpun ? 'Again': 'the Wheel'}</button>
          </div>
          
        </div>

        <div className="locations" style={{top: offset + 'vh', transitionDuration: useTranstion ? (speed * .001) + 's' : '0s'}}>
          {currentSet.map((location) => (
              <Location location={location} showText={showText}/>
          ))}
        </div>
        
      </div>
    </div>
  );
}

export default App;
