export const locations = [
    {
        name: 'Oasis',
        description: "Several pools of fresh water and palm trees offer respite from the desert beyond it. Note the ancient ruins — this small area was once a means of life in a bygone age.",
        image: '/Warzone-2-Al-Mazrah-Oasis.jpg'
    },
    {
        name: 'Taraq Village',
        description: "A United Offensive reduced this once peaceful village to mere Rust and rubble. The tight confines of these areas make it conducive to small squad combat, especially duels.",
        image: '/Warzone-2-Al-Mazrah-Taraq-Village.jpg'
    },
    {
        name: 'Rohan Oil',
        description: "One of the largest employers in the region, the Rohan Oil refinery is positioned strategically near some of Adal’s oldest petroleum derricks. Note the living quarters to the north and south of this otherwise sprawling factory.",
        image: '/Warzone-2-Al-Mazrah-Rohan-Oil.jpg'
    },
    {
        name: 'Al Safwa Quarry',
        description: "Massive deposits of limestone make the Quarry one of the three largest industrial areas in Al Mazrah, with nearly a dozen warehouses and hundreds of individually chiseled stone blocks.",
        image: '/Warzone-2-Al-Mazrah-Quarry-Al-Safwa-Quarry.jpg'
    },
    {
        name: 'Al Mazrah City',
        description: "The past 100 years of Adal history encapsulated: High rises and modern buildings are at the core of the largest point of interest in Al Mazrah. It was planned to be the ideal city — it soon welcomed nearly a million people, who were pushed out to the slums on the outskirts.",
        image: '/Warzone-2-Al-Mazrah-Al-Mazrah-City.jpg'
    },
    {
        name: 'Zarqwa Hydroelectric',
        description: "The cradle of civilization now comes full circle to the modern era with the advent of renewable energy. A mix of historical landmarks, midcentury living arrangements, and modern industrial buildings are scattered around the rivers that create electricity for this small town, as well as for the entire region.",
        image: '/Warzone-2-Al-Mazrah-Hydroelectric-Zarqwa.jpg'
    },
    {
        name: 'Mawizeh Marshlands',
        description: "The Marshlands was once the home of the ambitious River Diamond Luxury Resort in the 20th century. Now, due to rising river levels, it and the town around it are completely flooded, both with water from the rivers and trash flowing from the city to the north.",
        image: '/Warzone-2-Al-Mazrah-Marshlands-Mawizeh.jpg'
    },
    {
        name: 'Sattiq Caves',
        description: "A deep network of underground hideouts lies in plain sight of a small village — where a plane once crashed — built on the mountainside. Elsewhere, a tall communications tower and small collection of homes span back toward the Quarry.",
        image: '/Warzone-2-Al-Mazrah-Caves-Sattiq.jpg'
    },
    {
        name: 'Zaya Observatory',
        description: "Atop the tallest mountain in Al Mazrah, several Domed structures, including a destroyed one, help the region chart out the stars. Note the research facilities between each of the observation buildings.",
        image: '/Warzone-2-Al-Mazrah-Observatory-Zaya.jpg'
    },
    {
        name: 'Al Sharim Pass',
        description: "This old monastery and mausoleum overlook a modern village and zoo. The tallest hill contains several modernized buildings over the rest of the area, partially destroyed due to the elements and the war.",
        image: '/Warzone-2-Al-Mazrah-Al-Sharim-Pass.jpg'
    },
    {
        name: 'Ahkdar Village',
        description: "“An Old Town Fit for a Showdown,” as one writer covering an old coup once wrote. The world heritage site consists of a central courtyard, and market areas define the western half, while more modern apartments make up the east.",
        image: '/Warzone-2-Al-Mazrah-Ahkdar-Village.jpg'
    },
    {
        name: "Sa'id City",
        description: "An extensive modern mall is juxtaposed by old apartments that housed generations of port workers. The area also contains a medieval-themed carnival and a minor league football stadium.",
        image: '/Warzone-2-Al-Mazrah-Said-City.jpg'
    },{
        name: 'Hafid Port',
        description: "The Port is the economic gemstone of Al Mazrah, ending the Rohan Oil pipeline out to the greater seas. Several large warehouse structures can be found inland, while the Port itself has little containers due to the international economic restrictions for the region.",
        image: '/Warzone-2-Al-Mazrah-Port-Hafid.jpg'
    },
    {
        name: 'Sawah Village',
        description: "Rising sea levels caused this once-bustling coastal village to be submerged underwater, with a crashed freight ship being a symptom rather than the cause of the evacuated population.",
        image: '/Warzone-2-Al-Mazrah-Sawah-Village.jpg'
    },
    {
        name: 'El Samman Cemetery',
        description: "A monument to the fallen, a significant graveyard is now mostly derelict and unkempt, especially with a collapsed water tower flooding dozens of graves. The area is completely open to the elements, with a broken stone wall defining its boundaries.",
        image: '/Warzone-2-Al-Mazrah-Cemetery-El-Samman.jpg'
    },
    {
        name: 'Sariff Bay',
        description: "Home to the country’s valuable fisheries, this Bay maintains the history of Al Mazrah, as well as Adal. It contains a vibrant downtown section with suburbs, a modern resort, and a few small islands.",
        image: '/Warzone-2-Al-Mazrah-Sariff-Bay.jpg'
    },
    {
        name: 'Al Bagra Fortress',
        description: "The definition of the region’s medieval history, this walled island city was a museum dedicated to displaying its cultural significance up until occupation. Note the large crab factory on the east end, a spillover from the adjacent Sariff Bay.",
        image: '/Warzone-2-Al-Mazrah-Al-Bagra-Fortress.jpg'
    },
    {
        name: 'Al Malik Airport',
        description: "Terminal to the south tip of Al Mazrah, this modern airport mixes traditional and modern architecture to host both business and leisure activities. The observation tower near the sea is extremely approachable.",
        image: '/Warzone-2-Al-Mazrah-Airport-Al-Malik.jpg'
    }
]