import React from "react";

export default function Location({location,showText}) {
    return (
        <div className="location" style={{backgroundImage: `url(${location.image})`}}>
            <div className="text" style={{opacity: showText ? 1 : 0}}>
                <h1>{location.name}</h1>
                <p>{location.description}</p>
            </div>
        </div>
    )
}

